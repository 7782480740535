.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}
.ant-layout .ant-layout-sider {
	position: relative;
	min-width: 0;
	background: none !important;
	transition: all 0.2s;
}
.ant-card .ant-card-cover img {
	border-radius: 8px 8px 0 0 !important;
}

/* This will apply to all scrollbars in your application */
::-webkit-scrollbar {
	width: 25px; /* Adjust width of scrollbar */
}

::-webkit-scrollbar-track {
	background: #eeeeee; /* Set the track (background) color to the base color of the theme */

}

::-webkit-scrollbar-thumb {
	background: #008080; /* Set the thumb (moving part) color to the primary color of the theme */
	border-radius: 15px;
	border-style:solid;
	border-width: 6px;
	border-color: #eeeeee;
}
.wrapTrigger {
	text-align: center;
}
.App-header {
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: black;
}


.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
